.profile_container {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  display: flex;
}

.my-pic {
  position: relative;
  margin: 0 auto;
  width: 70%;
  top: 1vh;
}

.my-pic img {
  width: 100%;
  padding: 2vh;
  background-color: #333;
  border-radius: 50%;
}

.prof-box {
  padding: 2vh;
  position: relative;
  top: 2vh;
  height: 100%;
  width: 80%;
  margin: 0 auto;
  background-color: #333;
}

.prof-box p {
  color: white;
  font-size: 1.75vh;
  font-family: "Verdana", "sans-serif";
}

.left_profile {
  flex-grow: 1;
  width: 33%;
  background-image: linear-gradient(to top right, #004c91, #007DB8);
  height: 100%;
  min-height: 90vh;
}

.right_profile {
  width: 50%;
  height: 100%;
  flex-grow: 1;
  margin: 5vh 10vh;
}

.right_profile h1{
  text-align: center;
  font-size: 5vh;
  color: black;
}

.right_profile p {
  color: black;
  text-align: left;
  padding-left: 5vh;
  padding-top: 5vh;
  font-size: 2.5vh;
  font-family: "Verdana", "sans-serif";
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .profile_container {
    width: 100%;
    height: 100%;
    min-height: 150vh;
    display: block;
  }

  body .left_profile {
    width: 100%;
    background-image: linear-gradient(to top right, #004c91, #007DB8);
    height: 100%;
  }

  body .right_profile {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 2vh;
    /* padding-right: 3vh; */
  }

  body .prof-box {
    padding: 2vh;
    position: relative;
    top: 5vh;
    height: 50%;
    width: 80%;
    background-color: #333;
  }

  body .prof-box p {
    color: white;
    font-size: 1.45vh;
    font-family: "Verdana", "sans-serif";
  }

  body .right_profile h1{
    text-align: center;
    color: black;
  }

  body .right_profile p {
    color: black;
    text-align: left;
    padding-left: 0%;
    font-size: 1.65vh;
    font-family: "Verdana", "sans-serif";
  }

  body .my-pic {
    position: relative;
    top: 2vh;
    margin: auto;
    width: 70%;
    padding: 2vh;

    background-color: #333;
    border-radius: 50%;
  }

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  .left_profile {
    float: left;
    width: 35%;
    background-image: linear-gradient(to top right, #004c91, #007DB8);
    height: 100%;
  }

  .right_profile {
    width: 60%;
    height: 100%;
    float: left;
    /* padding-right: 3vh; */
  }

  .prof-box {
    padding: 2vh;
    position: relative;
    top: 10%;
    height: 30%;
    width: 80%;
    left: 2.5%;
    background-color: #333;
  }

  .right_profile h1{
    text-align: center;
    color: black;
  }

  .right_profile p {
    color: black;
    text-align: left;
    padding-left: 3.5vh;
    padding-top: 5vh;
    font-size: 2.5vh;
    font-family: "Verdana", "sans-serif";
  }

  .my-pic {
    position: relative;
    top: 2vh;
    left: 8%;
    width: 70%;
    padding: 2vh;
    background-color: #333;
    border-radius: 50%;
  }

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .profile_container {
    width: 100%;
    height: 100%;
    min-height: 150vh;
    display: block;
  }

  body .left_profile {
    width: 100%;
    background-image: linear-gradient(to top right, #004c91, #007DB8);
    height: 100%;
  }

  body .right_profile {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 2vh;
    /* padding-right: 3vh; */
  }

  body .prof-box {
    padding: 2vh;
    position: relative;
    top: 5vh;
    height: 50%;
    width: 80%;
    background-color: #333;
  }

  body .prof-box p {
    color: white;
    font-size: 1.45vh;
    font-family: "Verdana", "sans-serif";
  }

  body .right_profile h1{
    text-align: center;
    color: black;
  }

  body .right_profile p {
    color: black;
    text-align: left;
    padding-left: 0%;
    font-size: 1.65vh;
    font-family: "Verdana", "sans-serif";
  }

  body .my-pic {
    position: relative;
    top: 2vh;
    margin: auto;
    width: 70%;
    padding: 2vh;

    background-color: #333;
    border-radius: 50%;
  }

}
