.blogpost {
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 25vh;
  color: white;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  margin-bottom: 2vh;
}


.blogpost_desc {
  width: 100%;
  padding: 1vh;
  height: 100%;
  margin: auto 0;
  background-color: #333;
  flex: 1;
  box-shadow: rgba(0,0,0,.2) 2vh 2vh 2vh;
}

.blogpost_desc:hover {
  background-color: #000;
}

.blogpost_desc:hover .blogpost_info {
  background-color: #555;
  color: white;
}

.blogpost_desc img {
  float: left;
  width: 25%;
  height: 100%;
}

.blogpost_desc:hover img {
  background-color: #333;
  opacity: 0.5;
}

.blogpost_info {
  vertical-align: middle;
  background-color: #D3D3D3;
  width: 74.5%;
  height: 100%;
  padding: 2vh;
  flex: 1;
  float: right;
  color: black;
}

.blogpost_info h2 {
  text-align: center;
  font-size: 5vh;
}

.blogpost_info h4 {
  text-align: center;
}

@media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  .blogpost {
    width: 80%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: 25vh;
    color: white;
    padding: 1vh;
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }


  .blogpost_desc {
    width: 100%;
    padding: 1vh;
    height: 100%;
    margin: auto 0;
    background-color: #333;
    flex: 1;
    box-shadow: rgba(0,0,0,.2) 2vh 2vh 2vh;
  }

  .blogpost_desc:hover {
    background-color: #000;
  }

  .blogpost_desc:hover .blogpost_info {
    background-color: #555;
    color: white;
  }

  .blogpost_desc img {
    float: none;
    width: 100%;
    height: 100%;
  }

  .blogpost_desc:hover img {
    background-color: #333;
    opacity: 0.5;
  }

  .blogpost_info {
    background-color: #D3D3D3;
    width: 100%;
    height: 100%;
    padding: 2vh;
    flex: 1;
    float: none;
    color: black;
  }

  .blogpost_info h2 {
    text-align: center;
    font-size: 5vh;
  }

  .blogpost_info h4 {
    text-align: center;
  }

}
