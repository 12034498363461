.blue {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: 25em;
  top: 1vh;
  width: 80%;
  margin-top: 2em;
}

.blue p {
  color: white;
  line-height: normal;
  font-size: 5vh;
  position: absolute;
  font-family: "Verdana", "sans-serif";
  top: 40%;
  display: block;
  margin-top: -5vh;
  margin-left: 5%;
  margin-right: 5%;
  bottom: 2em;
}

.blue:hover p {
  display: none;
}

.blue::before{
  content: '';
  position: absolute;
  background: rgba(0, 76, 145, 0.4);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blue:hover:before{
  background: rgba(0, 0, 0, 0);
}

.drop-down-img {
  width: 100%;
  height: 100%;
  display: block;
  outline: solid 1vh #333;
}

.drop-info {
  top: 1vh;
  min-height: 20vh;
  flex-direction: column;
  width: calc(80% + 1.8vh);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: #333;
  color: white;
  display: block;
  padding: 2em;
}

.drop-left {
  float: left;
  background-image: linear-gradient(to top right, #004c91, #007DB8);
  height: 100%;
  width: 33%;
  padding: 0.5em;
  flex: 1;

}

.drop-right {
  background-color: #D3D3D3;
  height: 100%;
  width: 61%;
  float: right;
  padding: 0.5em;
  flex: 1;
  color: black;
}

.drop-right h2 {
  text-align: center;
}
