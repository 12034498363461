.project {
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 25vh;
  background-image: linear-gradient(to top right, #004c91, #007DB8);
  color: white;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.project_desc {
  width: 100%;
  padding: 1vh;
  height: 100%;
  margin: auto 0;
  background-color: #333;
  flex: 1;
}

.project_desc img {
  float: left;
  width: 49.5%;
  height: 100%;
}

.project_info {
  background-color: #D3D3D3;
  width: 49.5%;
  height: 100%;
  padding: 2vh;
  flex: 1;
  float: right;
  color: black;
}

.project_info h2 {
  font-size: 3vh;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .project {
    width: 80%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: 25vh;
    background-image: linear-gradient(to top right, #004c91, #007DB8);
    color: white;
    padding: 1vh;
    flex-direction: row;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .project_desc {
    width: 100%;
    padding: 1vh;
    height: 100%;
    margin: auto 0;
    background-color: #333;
  }

  .project_desc img {
    width: 100%;
    height: 50%;
    float: none;
  }

  .project_info {
    background-color: #D3D3D3;
    width: 100%;
    height: 100%;
    padding: 2vh;
    float: none;
    color: black;
  }

  .project_info h2 {
    font-size: 3vh;
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  .project {
    width: 80%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: 25vh;
    background-image: linear-gradient(to top right, #004c91, #007DB8);
    color: white;
    padding: 1vh;
    flex-direction: row;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .project_desc {
    width: 100%;
    padding: 1vh;
    height: 100%;
    margin: auto 0;
    background-color: #333;
  }

  .project_desc img {
    width: 100%;
    height: 50%;
    float: none;
  }

  .project_info {
    background-color: #D3D3D3;
    width: 100%;
    height: 100%;
    padding: 2vh;
    float: none;
    color: black;
  }

  .project_info h2 {
    font-size: 3vh;
  }

}
