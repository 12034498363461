.blogdetails {
  width: 80%;
  margin: auto;
}

.blogdetails h2 {
  text-align: center;
  font-size: 5vh;
}

.blogdetails h3 {
  font-weight: bold;
}
